<template>
  <div class="modelMenu">
    <router-link
        to="/Survey"
        :class="['menu_text', { disabled: surveyPending }]"
        :event="isDisabled ? '' : 'click'"
        @click.native.prevent="handClickRouter(surveyPending,'/Survey',$event)"
    >
      <i class="el-icon-s-claim"></i>
      <template v-if="tabSurveyAct===0">
        <span> Survey </span>
      </template>
      <template v-else>
        <el-badge :value="tabSurveyAct" class="item">
          <span> Survey </span>
        </el-badge>
      </template>
    </router-link>

    <router-link :to="'/home/'+$store.state.custKey" class="menu_text" exact >
      <i class="el-icon-s-home"></i>
<!--      <span> Sign in</span>-->
      <span> My Preferences</span>
    </router-link>
    <!-- <router-link to="/MyBenefit" class="menu_text"   @click.native="collapse1" exact>
      <i class="el-icon-s-claim"></i>
      <span> My Benefit</span>
    </router-link> -->

<!--    <router-link to="/MyPreference" class="menu_text" exact  @click.native="collapse1">-->
<!--      <i class="el-icon-knife-fork"></i>-->
<!--      <span> My Preference</span>-->
<!--    </router-link>-->

    <router-link to="/RateMyMeals" class="menu_text" exact  @click.native="collapse1">
      <i class="el-icon-star-on"></i>
      <span> Rate My Meals</span>
    </router-link>

    <router-link to="/ModifyMyMeals" class="menu_text" exact
                 :class="['menu_text', { disabled: tabPick }]"
                 :event="isDisabled ? '' : 'click'"
                 @click.native.prevent="handClickRouter(tabPick,'/ModifyMyMeals')"
    >
      <i class="el-icon-edit-outline"></i>
      <template v-if="tabPickAct===0">
        <span> Pick My Meals</span>
      </template>
      <template v-else>
        <el-badge :value="tabPickAct" class="item">
          <span> Pick My Meals</span>
        </el-badge>
      </template>

    </router-link>

<!--    <router-link to="/ModifyMyMeals" class="menu_text" exact  @click.native="collapse1">-->
<!--      <i class="el-icon-edit-outline"></i>-->
<!--      <span> Pick My Meals</span>-->
<!--    </router-link>-->
  </div>
</template>

<script>
export default {
  props: ["collapse"],
      data(){
        return {
          custKey: '',
          retract: "el-icon-s-fold",
          identifying: true,
          myId: "",
          isDisabled: true,
          mercatoDisable: true,
          surveyPending: true,
          tabPref: true,
          tabRate: true,
          tabPick: true,
          tabGroc: true,
          disableMenu: {},
          tabSurveyAct: 0,
          tabRateAct: 0,
          tabPickAct: 0,
        }
    },
  methods: {

    collapse1() {
      this.$emit("collapse");
    },
    // getSurveyPending() {
    //   // Get the current URL
    //   const currentUrl = window.location.href;
    //   // Use a regular expression to extract the UUID part
    //   const uuidRegex = /\/home\/([^/]+)/;
    //   const match = currentUrl.match(uuidRegex);
    //   const key = sessionStorage.getItem("custKey");
    //   const cookieValue = this.getCookie("custKey");
    //
    //   //  URL  key， sessionStorage， cookie
    //   if (match && match[1]) {
    //     console.log("111111111111")
    //     const custKey = match[1];
    //     this.callSurveyPendingAPI(custKey);
    //   } else if (key) {
    //     console.log("22222222222")
    //     this.callSurveyPendingAPI(key);
    //   } else if (cookieValue) {
    //     console.log("3333333333333")
    //     this.$store.commit("setCustKey", cookieValue);
    //     this.callSurveyPendingAPI(cookieValue);
    //   } else {
    //     console.log("4444444444444")
    //     console.error("Could not extract custKey from URL");
    //     this.$message.error("Invalid URL format");
    //   }
    // },
    getSurveyPending() {
      const currentUrl = window.location.href;
      const uuidRegex = /\/home\/([^/]+)/;
      const match = currentUrl.match(uuidRegex);
      const key = sessionStorage.getItem("custKey");
      const cookieValue = this.getCookie("custKey");

      return new Promise((resolve) => {
        let custKeyToUse;

        if (match && match[1]) {
          custKeyToUse = match[1];
        } else if (key) {
          custKeyToUse = key;
        } else if (cookieValue) {
          custKeyToUse = cookieValue;
        } else {
          try {
            const myInfo = sessionStorage.getItem("myInfo");
            if (myInfo) {
              const parsedInfo = JSON.parse(decryptEs(myInfo));
              if (parsedInfo && parsedInfo.custKey) {
                custKeyToUse = parsedInfo.custKey;
              }
            }
          } catch (error) {
            console.error("Error parsing myInfo:", error);
          }
        }

        if (custKeyToUse) {
          console.log("iuuuuuuuuuuuuuuuuu")
          this.$store.commit("setCustKey", custKeyToUse);
          this.callSurveyPendingAPI(custKeyToUse)
              .then(() => resolve())
              .catch(() => resolve());
        } else {
          resolve();
        }
      });
    },
    // callSurveyPendingAPI(custKey) {
    //   this.$https.getSurveyPending(custKey).then((res) => {
    //     if (res.code === 200) {
    //       this.tabGroc = !(res.data.tabGroc != null && res.data.tabGroc !== 0);
    //       this.tabPick = !(res.data.tabPick != null && res.data.tabPick !== 0);
    //       this.tabRate = !(res.data.tabRate != null && res.data.tabRate !== 0);
    //       this.tabPref = !(res.data.tabPref != null && res.data.tabPref !== 0);
    //       this.surveyPending = !(res.data.surveyPending != null && res.data.surveyPending !== 0);
    //       this.tabSurveyAct = res.data.tabSurveyAct;
    //       this.tabRateAct = res.data.tabRateAct;
    //       this.tabPickAct = res.data.tabPickAct;
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   }).catch((error) => {
    //     console.error("Error fetching survey pending data:", error);
    //     this.$message.error("Failed to fetch survey data");
    //   });
    // },
    callSurveyPendingAPI(custKey) {
      return this.$https.getSurveyPending(custKey).then((res) => {
        if (res.code == 200) {
          this.tabGroc = !(res.data.tabGroc != null && res.data.tabGroc !== 0);
          console.log(this.tabGroc);
          this.tabPick = !(res.data.tabPick != null && res.data.tabPick !== 0);
          console.log(this.tabPick);
          this.tabRate = !(res.data.tabRate != null && res.data.tabRate !== 0);
          console.log(this.tabRate);
          this.tabPref = !(res.data.tabPref != null && res.data.tabPref !== 0);
          console.log(this.tabPref);
          this.surveyPending = !(res.data.surveyPending != null && res.data.surveyPending !== 0);
          console.log(this.surveyPending);

          this.tabSurveyAct = Number(res.data.tabSurveyAct || 0);
          console.log(this.tabSurveyAct);
          this.tabRateAct = Number(res.data.tabRateAct || 0);
          console.log(this.tabRateAct);
          this.tabPickAct = Number(res.data.tabPickAct || 0);
          console.log(this.tabPick);

          this.$nextTick(() => {
            this.$forceUpdate();
          });
        } else {
          this.$message.error(res.msg);
        }
      }).catch((error) => {
        console.error("Error fetching survey pending data:", error);
        this.$message.error("Failed to fetch survey data");
      });
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
      return '';
    },
    handClickRouter(e,a,event){
      if (e) {
        event.preventDefault();
        // Optionally, you can show a message to the user
        // this.$message.warning('This feature is currently disabled');
      } else {
        // Only navigate if not disabled
        this.$router.push(a).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },

  },
  created() {
    this.getSurveyPending().then(() => {
      console.log('Survey pending status loaded');
    });

  }
};
</script>

<style lang="scss" scoped>
                   ::v-deep .el-badge__content.is-fixed {
                     position: absolute;
                     top: 12px;
                     right: 0px;
                     transform: translateY(-50%) translateX(100%);
                   }
.modelMenu {
  display: flex;
  flex-direction: column;
  background: #a0c572;


  a {
    //display: block;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgb(244, 249, 247);
    font-size: 16px;
    color: #FFF;
    font-weight: 500;
    padding-left: 4%;

  }
  i {
    color: #FFF;
  }
}
.custom-icon {
  display: inline-block;
  vertical-align: middle;
  //background: url("../static/csc.png") center center no-repeat;
  //background-size: contain; /*  cover， */
  margin-right: 5px;
}

.custom-icon-more {
  width: 29px;
  height: 28px;
  margin-top: 6px;
}
.menu_text {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 50px;

  i {
    font-size: 30px;
    margin-right: 5px;
    padding-left: 0;
    padding-top: 5px;
  }
  span {
    line-height: 1;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.disabled::after {
    //content: "⛔";
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &.disabled:hover::after {
    opacity: 1;
  }


}

</style>
