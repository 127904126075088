<template>
  <div class="home">
    <div class="left_menu">
      <div class="menu_head">
        <img :src="src" class="head_img" :class="{ collapsed: ac }" />
      </div>
      <div class="menu_abdomen">


        <router-link
            to="/Survey"
            :class="['menu_text', { disabled: surveyPending }]"
            :event="isDisabled ? '' : 'click'"
            @click.native.prevent="handClickRouter(surveyPending,'/Survey',$event)"
        >
          <i class="el-icon-s-claim"></i>
          <template v-if="tabSurveyAct===0">
            <span> Survey </span>
          </template>
          <template v-else>
            <el-badge :value="tabSurveyAct" class="item">
              <span> Survey </span>
            </el-badge>
          </template>
        </router-link>


        <router-link :to="'/home/' + $store.state.custKey + '/#?' +$store.state.user" class="menu_text" exact>
          <i class="el-icon-s-home"></i>
<!--          <span> Sign in</span>-->
          <span> My Preferences </span>
        </router-link>
        <!-- <router-link to="/MyBenefit" class="menu_text" exact>
          <i class="el-icon-s-claim"></i>
          <span> My Benefit</span>
        </router-link> -->

        <!-- <router-link to="/MyPreference" class="menu_text" exact v-if="$store.state.isLogin"> -->
        <!-- <i class="el-icon-knife-fork"></i> -->
        <!-- <span> My Preference</span> -->
        <!-- </router-link> -->
        <!--  -->
<!--        <router-link to="/MyPreference" class="menu_text" exact>-->
<!--          <i class="el-icon-knife-fork"></i>-->
<!--          <span> My Preference</span>-->
<!--        </router-link>-->
<!--        <router-link to="/MyPreference" class="menu_text" exact>-->
<!--          <i class="el-icon-knife-fork"></i>-->
<!--          <span> My Preference</span>-->
<!--        </router-link>-->

        <router-link to="/RateMyMeals" class="menu_text" exact>
          <i class="el-icon-star-on"></i>
          <span> Rate My Meals</span>
        </router-link>

<!--        <router-link to="/ModifyMyMeals" class="menu_text" exact>-->
<!--          <i class="el-icon-edit-outline"></i>-->
<!--          <span> Pick My Meals</span>-->
<!--        </router-link>-->
        <router-link to="/ModifyMyMeals" class="menu_text" exact
                     :class="['menu_text', { disabled: tabPick }]"
                     :event="isDisabled ? '' : 'click'"
                     @click.native.prevent="handClickRouter(tabPick,'/ModifyMyMeals')"
        >
          <i class="el-icon-edit-outline"></i>
          <template v-if="tabPickAct===0">
            <span> Pick My Meals</span>
          </template>
          <template v-else>
            <el-badge :value="tabPickAct" class="item">
              <span> Pick My Meals</span>
            </el-badge>
          </template>

        </router-link>

        <div
            class="menu_text"
            :class="['menu_text', { disabled: tabGroc || mercatoDisable }]"
            @click="handleMercatoNavigation"
            style="cursor: pointer;"
        >
          <template v-if="tabGrocAct===0">
            <div class="custom-icon">
              <img class="custom-icon-more" src="../static/csc.png">
            </div>
            <span style="color: #333;">Pick My Groceries</span>
          </template>
          <template v-else>
            <div class="custom-icon">
              <img class="custom-icon-more" src="../static/csc.png">
            </div>
            <el-badge :value="tabGrocAct" class="item">
              <span style="color: #333;">Pick My Groceries</span>
            </el-badge>
          </template>
        </div>

      </div>
      <div class="menu_tail"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeView",
  components: {
    // HelloWorld
  },
  props: {
    src: {
      type: String,
      // required: true,
    },
    ac: {
      type: Boolean,
    },
  },
  data() {
    return {
      custKey: '',
      retract: "el-icon-s-fold",
      identifying: true,
      myId: "",
      isDisabled: true,
      mercatoDisable: true,
      surveyPending: true,
      tabPref: true,
      tabRate: true,
      tabPick: true,
      tabGroc: true,
      disableMenu: {},
      tabSurveyAct: 0,
      tabRateAct: 0,
      tabPickAct: 0,
      tabGrocAct:0,
      ssoToken: null,
      mercatoBaseUrl: 'https://s0.pwl.mercato.com',
    };
  },

  mounted() {},
  methods: {
    async getMercatoSSOToken(custKey) {
      try {
        const response = await this.$https.getSSOToken(custKey);
        if (response.success && response.token) {
          this.ssoToken = response.token;
          return true;
        }
        return false;
      } catch (error) {
        console.error("Error fetching SSO token:", error);
        return false;
      }
    },
    async handleMercatoNavigation() {
      if (!this.mercatoDisable && this.ssoToken) {
        const mercatoUrl = `${this.mercatoBaseUrl}/?participantId=${this.custKey}&ssoToken=${this.ssoToken}`;
        console.log(mercatoUrl)
        window.location.href = mercatoUrl;
      }
    },
    clickRoute(e) {
      this.$router.push(e);
    },

    getSurveyPending() {
      return new Promise((resolve) => {
        let custKeyToUse = this.$store.state.custKey;
        if (!custKeyToUse) {
          custKeyToUse = sessionStorage.getItem("custKey");
        }
        if (!custKeyToUse) {
          custKeyToUse = this.getCookie("custKey");
        }
        if (!custKeyToUse) {
          const currentUrl = window.location.href;
          const uuidRegex = /\/home\/([^/]+)/;
          const match = currentUrl.match(uuidRegex);
          if (match && match[1]) {
            custKeyToUse = match[1];
          }
        }

        if (custKeyToUse) {
          this.custKey = custKeyToUse;
          this.$https.getSurveyPending(custKeyToUse).then((res) => {
            if (res.code === 200) {
              this.updateMenuStatus(res.data,custKeyToUse);
              resolve();
            } else {
              this.$message.error(res.msg);
              resolve();
            }
          }).catch((error) => {
            this.$message.error("Failed to fetch survey data");
            resolve();
          });
        } else {
          resolve();
        }
      });
    },

    async updateMenuStatus(data,custKey) {
      this.tabGroc = !(data.tabGroc != null && data.tabGroc !== 0);
      this.tabPick = !(data.tabPick != null && data.tabPick !== 0);
      this.tabRate = !(data.tabRate != null && data.tabRate !== 0);
      this.tabPref = !(data.tabPref != null && data.tabPref !== 0);
      this.surveyPending = !(data.surveyPending != null && data.surveyPending !== 0);
      this.tabSurveyAct = Number(data.tabSurveyAct || 0);
      this.tabRateAct = Number(data.tabRateAct || 0);
      this.tabPickAct = Number(data.tabPickAct || 0);
      this.tabGrocAct =Number(data.tabGrocAct || 0);

      if (!this.tabGroc) {
        const hasValidToken = await this.getMercatoSSOToken(custKey);
        this.mercatoDisable = !hasValidToken;
      }

      console.log('Menu status updated:', {
        tabPickAct: this.tabPickAct,
        tabRateAct: this.tabRateAct
      });
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
      return '';
    },
    handClickRouter(e,a,event){
      if (e) {
        console.log("zo")
        event.preventDefault();
        // Optionally, you can show a message to the user
        // this.$message.warning('This feature is currently disabled');
      } else {
        // Only navigate if not disabled
        this.$router.push(a).catch(err => {
          console.log("a",a);
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },

  },
  created() {
    this.getSurveyPending()

  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-badge__content.is-fixed {
  position: absolute;
  top: 2px;
  right: 1px;
  transform: translateY(-50%) translateX(100%);
}
.custom-icon {
  display: inline-block;
  vertical-align: middle;
  //background: url("../static/csc.png") center center no-repeat;
  //background-size: contain;
  margin-right: 5px;
}

.custom-icon-more {
  width: 28px;
  height: 28px;

}

.router-link-active {
  color: #6aa66a !important;

  a,
  i {
    color: #6aa66a !important;
  }
}

.home {
  width: 100%;
  height: 100%;
}

.left_menu {
  width: 100%;
  height: 100%;
  // background: #a0c572;
  background: #fff;
  // background: #a0c572;
  overflow: hidden;
  transition: all 0.3s;

  a {
    // color: #fff;
    color: #333;

    i {
      color: #c9d0c9;
    }
  }

  .menu_head {
    padding-top: 20%;

    .head_img {
      width: 80%;
      // height: 70%;

      &.collapsed {
        width: 50%;
        height: 50%;
      }
    }
  }

  .menu_abdomen {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60%;
    padding-top: 20%;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    // justify-content: center;
    align-items: center;

    .menu_text {
      display: flex;
      justify-content: left;
      align-items: center;


      //text-align: left;
      width: 80%;
      height: 50px;
      //display: flex;
      //align-items: center;
      //justify-content: left;
      //cursor: pointer;
      //position: relative;
      //text-decoration: none;
      //color: #333;

      i {
        font-size: 30px;
        margin-right: 5px;
        padding-left: 0;
      }

      //i.custom-icon {
      //  padding-left: 30%;
      //}

      span {
        line-height: 1;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.disabled::after {
        //content: "⛔";
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &.disabled:hover::after {
        opacity: 1;
      }

    }
  }


}
@media screen and (max-width: 1590px)  {
  .menu_abdomen span{
    font-size: 16px !important;
  }

}


@media screen and (max-width: 1586px)  {
  .menu_abdomen span{
    font-size: 16px !important;
  }

}


@media screen and (max-width: 1525px) {
  .menu_abdomen span{
    font-size: 15px !important;
  }

}

@media screen and (max-width: 1373px) {
  .menu_abdomen span{
    font-size: 12px !important;
  }

}

@media screen and (max-width: 1290px) {
  .menu_abdomen span{
    font-size: 12px !important;
  }

}

@media screen and (max-width: 1149px)  {
  .menu_abdomen span{
    font-size: 10px !important;
  }

}

@media screen and (max-width: 1142px)  {
  .menu_abdomen span{
    font-size: 10px !important;
  }

}


@media screen and (min-width: 1526px) and (min-height: 743px) {
  //.menu_abdomen span{
  //  font-size: 15px !important;
  //}

}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .left_menu {
    .menu_abdomen {
      .menu_text {
        // text-align: left;
        justify-content: center;
        font-size: 35px;

        span {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .left_menu {
    display: none;
  }
}


</style>
<!--<style lang="scss" scoped>-->

<!--.router-link-active {-->
<!--  color: #6aa66a !important;-->
<!--  -->
<!--  -->
<!--  -->
<!--  a,-->
<!--  i {-->
<!--    font-size: 30px;-->
<!--    margin-right: 5px;-->
<!--    padding-left: 0;-->
<!--    padding-top: 5px;-->
<!--  }-->
<!--}-->
<!--.home {-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--}-->

<!--.left_menu {-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--  // background: #a0c572;-->
<!--  background: #fff;-->
<!--  // background: #a0c572;-->
<!--  overflow: hidden;-->
<!--  transition: all 0.3s;-->

<!--  a {-->
<!--    // color: #fff;-->
<!--    color: #333;-->
<!--    i {-->
<!--      color: #c9d0c9;-->
<!--    }-->
<!--  }-->

<!--  .menu_head {-->
<!--    padding-top: 20%;-->

<!--    .head_img {-->
<!--      width: 80%;-->
<!--      // height: 70%;-->

<!--      &.collapsed {-->
<!--        width: 50%;-->
<!--        height: 50%;-->
<!--      }-->
<!--    }-->
<!--  }-->

<!--  .menu_abdomen {-->
<!--    display: flex;-->
<!--    flex-direction: column;-->
<!--    width: 100%;-->
<!--    height: 60%;-->
<!--    padding-top: 20%;-->
<!--    color: #fff;-->
<!--    font-size: 18px;-->
<!--    font-weight: bold;-->
<!--    // justify-content: center;-->
<!--    align-items: center;-->

<!--    .menu_text {-->
<!--      text-align: left;-->
<!--      width: 80%;-->
<!--      height: 50px;-->
<!--      display: flex;-->
<!--      align-items: center;-->
<!--      justify-content: left;-->
<!--      cursor: pointer;-->
<!--      // line-height: 50px;-->

<!--      i {-->
<!--        font-size: 30px;-->
<!--        margin-right: 5px;-->
<!--      }-->
<!--      span {-->
<!--        line-height: 1;-->
<!--      }-->
<!--    }-->
<!--  }-->

<!--  .menu_tail {-->
<!--  }-->
<!--}-->

<!--@media screen and (max-width: 1400px) and (min-width: 1024px) {-->
<!--}-->
<!--// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {-->
<!--@media screen and (max-width: 1024px) and (min-width: 768px) {-->
<!--  .left_menu {-->
<!--    .menu_abdomen {-->
<!--      .menu_text {-->
<!--        // text-align: left;-->
<!--        justify-content: center;-->
<!--        font-size: 35px;-->
<!--        span {-->
<!--          display: none;-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->
<!--@media screen and (max-width: 768px) {-->
<!--  .left_menu {-->
<!--    display: none;-->
<!--  }-->
<!--}-->
<!--</style>-->
