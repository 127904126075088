/*
 * @Author: hanchunxuan 1627047609@qq.com
 * @Date: 2023-10-08 08:59:44
 * @LastEditors: hanchunxuan 1627047609@qq.com
 * @LastEditTime: 2023-10-14 11:20:35
 * @FilePath: \1008\zonghoutai-front\src\axios\request.js
 * @Description: ,`customMade`, koroFileHeader : https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * axios
 */
import axios from 'axios'


// import qs from 'querystring'

/**
 *  http
 * 2xx：
 * 3xx:
 * 4xx:
 * 5xx：
 *
 */
const  ErrorHandle=(status, info) => {
    switch (status) {
        case 400:
            console.log('400- indicates that there is a syntax error in the request message');
            break;
        case 401:
            console.log('401- Unauthorized, HTTP authentication required');
            break;
        case 403:
            console.log('403- The server refused the access (access permission issue)');
            break;
        case 404:
            console.log('404- Indicates that the requested resource cannot be found on the server');
            break;
        case 500:
            console.log('500- indicates that the server encountered an error while executing the request, which could also be a bug in the web application or some temporary error;');
            break;
        case 503:
            console.log('503- indicates that the server is temporarily overloaded or undergoing maintenance shutdown, unable to process requests');
            break;
        default:
            console.log(info);
            break;
    }
}

// axios
const install = axios.create({
    timeout: 5000,//
})


//
//
install.interceptors.request.use(function (config) {
    //post
    if(config.method==='post'){
        // config.data = qs.stringify(config.data)//{} xx=xx&xx=xx
    }
    let token = localStorage.getItem('mytoken')|| '';
         if (token) {
        //
        config.headers.Authorization = 'Bearer ' + token
        // config.headers['Authorization'] = 'Bearer ' + token // token
    }


    // console.log('--config',config);
    //
    return config;
  }, function (error) {
    //
    return Promise.reject(error);
  });

//
install.interceptors.response.use(
    response =>{
      return response.data

    },
    error=>{
        console.log('',error);
        let { response } = error;
        //
        ErrorHandle(response.status,response.info)
  });



export function get(url,params){
    return install.get(url,{
        params
    }).then(res=>res.data)
}

export default  install;

// import axios from 'axios';
// //
// import { encrypt, decrypt } from './encrypt';
//
// const ErrorHandle = (status, info) => {
//     // ...
// };
//
// const install = axios.create({
//     timeout: 5000, //
// });
//
// //
// install.interceptors.request.use(function (config) {
//     let token = localStorage.getItem('mytoken') || '';
//     if (token) {
//         config.headers.Authorization = 'Bearer ' + token;
//     }
//
//     // ，
//     if (config.data && !(config.data instanceof FormData)) {
//         //
//         const encryptedData = encrypt(JSON.stringify(config.data));
//         //
//         config.data = encryptedData;
//         // ，
//         config.headers['Content-Type'] = 'text/plain';
//     }
//
//     return config;
// }, function (error) {
//     return Promise.reject(error);
// });
//
// //
// install.interceptors.response.use(response => {
//     console.log(':', typeof response.data);
//     console.log(':', response.data);
//     // ，
//     if (response.data && typeof response.data === 'string') {
//         try {
//             const decryptedData = decrypt(response.data.data); //
//             console.log(decryptedData);
//             response.data = JSON.parse(decryptedData); // JSON
//         } catch (error) {
//             console.error('', error);
//         }
//     }
//     return response;
// }, error => {
//     console.log('', error);
//     let { response } = error;
//     ErrorHandle(response.status, response.info);
//     return Promise.reject(error);
// });
//
// export default install;
